import { createI18n } from 'vue-i18n';
import dateTimeFormats from './dateTimeFormats';

const i18n = createI18n({
  locale: document.body.dataset.locale,
  fallbackLocale: ['en-GB', 'en'],
  messages: {}, // Use Local Components translations via Translations.js
  datetimeFormats: dateTimeFormats,
});

export default i18n;
