import Translations from './Translations';
import * as deDE from './de-DE.json';
import * as frFR from './fr-FR.json';
import * as en from './en.json';
import * as enUS from './en-US.json';
import i18n from './config';

// prettier-ignore
Translations
  .locale('en', en.default)
  .locale('de-DE', deDE.default)
  .locale('fr-FR', frFR.default)
  .locale('en-US', enUS.default)
  .registerComponent('AddressForm')
  .registerComponent('AddressDialog')
  .registerComponent('AddressArea')
  .registerComponent('Upload')
  .registerComponent('AssemblyCalculator')
  .registerComponent('customConfirmation')
  .registerComponent('project', 'ProjectSpecCard')
  .registerComponent('project', 'ProjectCards')
  .registerComponent('project', 'ProjectCardsContainer')
  .registerComponent('project', 'ProjectShowcaseCards')
  .registerComponent('project', 'RevisionButton')
  .registerComponent('project', 'RevisionList')
  .registerComponent('project', 'ProjectCheckoutButton')
  .registerComponent('project', 'ProjectQuickLink')
  .registerComponent('project', 'SharingModalContent')
  .registerComponent('order', 'Checkout')
  .registerComponent('order', 'CheckoutSelection')
  .registerComponent('order', 'CountrySelect')
  .registerComponent('order', 'SepaInfoDialog')
  .registerComponent('order', 'PhoneNumberDialog')
  .registerComponent('BoardInspectorControls')
  .registerComponent('BoardInspectorLegend')
  .registerComponent('bom', 'AssemblyImpactNote')
  .registerComponent('bom', 'BomEditor')
  .registerComponent('bom', 'BomPartTable')
  .registerComponent('bom', 'Menu')
  .registerComponent('bom', 'PriceTable')
  .registerComponent('bom', 'RunningOrderNote')
  .registerComponent('bom', 'StatusNote')
  .registerComponent('bom', 'StockIndicator')
  .registerComponent('bom', 'part_search', 'CreateBomPartsTab')
  .registerComponent('bom', 'part_search', 'PartSearch')
  .registerComponent('bom', 'part_search', 'PhysicalPartList')
  .registerComponent('bom', 'part_search', 'SmartMatch')
  .registerComponent('support', 'Support')
  .registerComponent('support', 'SupportForm')
  .registerComponent('support', 'SupportTopicPicker')
  .registerComponent('order', 'OrdersContainer')
  .registerComponent('order', 'OrderCard')
  .registerComponent('quotations', 'QuotationsContainer')
  .registerComponent('quotations', 'QuotationCard')
  .registerComponent('quotations', 'NewQuotationCard')
  .registerComponent('quotations', 'SharingModal')
  .registerComponent('invoices', 'InvoicesContainer')
  .registerComponent('invoices', 'InvoiceCard')
  .registerComponent('dispatch', 'Dispatcher')
  .registerComponent('dispatch', 'Checkin')
  .registerComponent('dispatch', 'CheckinBoards')
  .registerComponent('dispatch', 'DispatchHistory')
  .registerComponent('StockSlotCard')
  .registerComponent('boards', 'BoardNotesBar')
  .registerComponent('boards', 'AssemblyBar')
  .registerComponent('boards', 'AssemblyUnavailableOverlay')
  .registerComponent('boards', 'Guide')
  .registerComponent('assembly', 'Guide')
  .registerComponent('assembly', 'Material')
  .registerComponent('assembly', 'OrderIndex')
  .registerComponent('helper', 'Positions')
  .registerComponent('WebGlViewer');

const components = Translations.components;
export default components;
export { i18n, components };
