import _ from 'lodash';

class Translations {
  constructor() {
    this.locales = {};
    this.components = {};

    this.locale = this.locale.bind(this);
  }

  locale(name, translations) {
    if (this.locales[name]) {
      throw new Error(`The name ${name} already exists`);
    }

    this.locales[name] = translations;

    return this;
  }

  isNotRegistered(path) {
    return _.chain(this.locales)
      .omitBy((translations) => _.has(translations, path))
      .keys()
      .value();
  }

  createMessages(path) {
    return _.mapValues(
      this.locales,
      (translations) => _.get(translations, path, {}),
    );
  }

  registerComponent(...componentPath) {
    const path = componentPath.join('.');
    const errors = this.isNotRegistered(path);

    if (errors.length > 0) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.debug(`The component translation for ${path} is not registered for locale "${errors.join(', ')}"`);
      }
    }

    const messages = {
      messages: this.createMessages(path),
    };

    _.set(this.components, path, messages);

    return this;
  }
}

export default new Translations();
